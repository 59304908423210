/* Colors (desktop & mobile)*/
$mainblue: #3e4b58;
$navblue:  #12161c;
$iconblue: #00bcd4;
$orange: #ff9800;
$ivory: ivory;
$cardbackground: #d1d3d4;

@import url('https://fonts.googleapis.com/css2?family=Cairo&display=swap');

/* Fonts */
$primaryfont: Cairo, Arial, 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;