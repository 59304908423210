@import "../../../src/assets/styles/variables";

.projectCard {
  display: grid;
  grid-template-rows: 40% 12% 38% 10%;
  justify-content: center;
  align-items: center;
  height: 275px;
  margin: 20px;
  border-radius: 10px;
  background-color: $cardbackground;
  box-shadow: 5px 5px 10px 2px black;
  border: 1.5px solid $orange;
  color: $navblue;
  font-weight: 700;

  .projectImgContainer {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    border-bottom: 2px solid $iconblue;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0px 1px 3px $navblue;
    margin: auto;
    padding: auto;
    justify-content: center;
    align-items: center;

    .projectImg {
      max-width: 150px;
      max-height: 100%;
      margin: auto;
      padding: 10px;
      border-radius: 14px;
    }
  }
  .projectTitle {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: white;
    border-bottom: 2px solid $iconblue;
    box-shadow: 0px 1px 3px $navblue;
    margin: auto;
    padding: auto;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    text-align: center;
    margin: auto;
    padding-bottom: 3px;
    line-height: 0;
  }
  .projectBody {
    font-size: 14px;
    text-align: center;
    margin: auto;
    line-height: 16px;
    padding: 5px 5px 5px 5px;
  }
  .projectLink {
    font-size: 15px;
    letter-spacing: 1px;
    text-align: center;
    margin: auto;
    padding-bottom: 8px;
    a {
    text-decoration: none;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .projectCard {
    height: 350px; //
    .projectImg {
      height: 150px; //
    }
    .projectTitle {
      font-size: 25px; //
    }
    .projectSubtitle {
      font-size: 20px; //
    }
    .projectBody {
      font-size: 16px; //
      line-height: 22px; //
    }
  }
}
