@import "../../../src/assets/styles/variables";

.greetingMain {
  padding-top: 35vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100%;
  line-height: 45px;
  .profilePic {
    width: auto;
    height: 20vh;
    object-fit: cover;
    background-size: contain;
    border-radius: 50%;
    border: 1px solid $navblue;
    box-shadow: 5px 5px 10px 2px black;
    border: 1.5px solid $orange;
  }
  .greetingBox {
    padding-bottom: 40vh;
    .greeting1 {
      color: ivory;
      font-size: 1.8rem;
      font-weight: bold;
      text-shadow: 1px 1px 2px $navblue;
    }
    .greeting2 {
      color: $orange;
      font-size: 2.2rem;
      font-weight: 700;
      letter-spacing: 3px;
      text-shadow: 1px 1px 2px $navblue;
    }
    .greeting3 {
      color: ivory;
      font-size: 1rem;
      letter-spacing: 3px;
      text-shadow: 1px 1px 2px $navblue;
      margin-bottom: 20px;
    }
  }
}

@media only screen and (min-width: 768px) {
  .greetingMain {
    .greetingBox {
      .greeting1 {
      font-size: 2rem;
      font-weight: bold;
      text-shadow: 1px 1px 2px $navblue;
    }
    .greeting2 {
      font-size: 2.8rem;
      font-weight: 700;
      letter-spacing: 3px;
      text-shadow: 1px 1px 2px $navblue;
    }
    .greeting3{
      font-size: 1.3rem;
      letter-spacing: 3px;
      text-shadow: 1px 1px 2px $navblue;
      margin-bottom: 20px;
    }
    }
    
    .profilePic {
      height: 20vh;
      border-radius: 10%;
      transition: ease 1s;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .greetingMain {
    .greetingBox {
      .greeting1 {
      font-size: 2.4rem;
      font-weight: bold;
      text-shadow: 1px 1px 2px $navblue;
    }
    .greeting2 {
      font-size: 3.2rem;
      font-weight: 700;
      letter-spacing: 3px;
      text-shadow: 1px 1px 2px $navblue;
    }
    .greeting3{
      font-size: 1.5rem;
      letter-spacing: 3px;
      text-shadow: 1px 1px 2px $navblue;
      margin-bottom: 20px;
    }
    }
    
    .profilePic {
      height: 22vh;
      border-radius: 10%;
      transition: ease 1s;
    }
  }
}