@import "../../../src/assets/styles/variables";

.aboutMePage {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0;
  padding-top: 65px;
  padding-bottom: 50px;
  min-height: 100vh;

  .aboutMeHeading {
    color: $orange;
    text-align: center;
    font-size: 28px;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px $navblue;
    padding-bottom: 20px;
  }
  .aboutMeCard {
    display: grid;
    grid-template-rows: 30px auto;
    background-color: $cardbackground;
    width: 90%;
    height: auto;
    max-width: 1075px;
    margin: 10px 0 20px 0;
    border-radius: 10px;
    box-shadow: 5px 5px 10px 2px black;
    border: 1.5px solid $orange;
    font-weight: 700;
    .subHeadingContainer {
      display: flex;
      width: 100%;
      height: 100%;
      font-size: 20px;
      background-color: white;
      border-bottom: 2px solid $iconblue;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      box-shadow: 0px 1px 3px $navblue;
      margin: auto;
      padding: auto;
      justify-content: center;
      align-items: center;
    }

    .bodyContainer {
      font-size: 16px;
      line-height: 25px;
      color: $navblue;
      padding: 10px;
      ul {
        list-style: none;
        
      }
      ol {
          margin-left: 25px;
        }
    }

  }
}

@media only screen and (min-width: 768px) {
  .aboutMePage {
    padding-top: 20px;
    .aboutMeHeading {
      font-size: 34px;
    }
    .aboutMeCard {
      width: unset;
      width: 70vw;
      
    }
  }
}

@media only screen and (min-width: 899px) {
  .aboutMePage {
    .aboutMeCard {
      width: unset;
      width: 70vw;
    }
  }
}

@media only screen and (min-width: 1024px) {
  .aboutMePage {
    .aboutMeCard {
      grid-template-rows: 40px auto;
      width: unset;
      width: 70vw;
      .subHeadingContainer {
        font-size: 23px;
      }
      .bodyContainer {
        font-size: 20px;
      line-height: 30px; 
      }
    }
  }
}
