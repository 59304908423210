@import '../src/assets/styles/variables';

* {
  box-sizing: border-box;
  font-family: $primaryfont;
  margin: 0;
  padding: 0;
}

body {
  background-color: $mainblue;
}

::-webkit-scrollbar {
  width: 18px;
}
::-webkit-scrollbar-track {
  background: $cardbackground;
}
::-webkit-scrollbar-thumb {
  background: $iconblue;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
  background: $orange;
}
