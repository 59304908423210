@import '../../../src/assets/styles/variables';

.skillsPage {
  justify-content: center;
  align-items: center;
  margin: 0;
  padding-top: 65px;
  min-height: 100vh;

  .skillsHeading {
    color: $orange;
    text-align: center;
    font-size: 28px;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px $navblue;
    padding-bottom: 20px;
  }
  .skillsCardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 370px));
    justify-content: center;
    align-content: center;
    margin: auto;
    max-width: 1250px; 
  }
}

@media only screen and (min-width: 750px) {
  .skillsPage {
    margin: 0 70px;
  }
}

@media only screen and (min-width: 768px) {
  .skillsPage {
    padding-top: 20px;
    .skillsHeading {
      font-size: 34px;
    }
  }
}

@media only screen and (min-width: 1024px) {

  .skillsPage {
    .skillsCardGrid {
        grid-template-columns: repeat(auto-fit, minmax(350px, 400px));
      }
  }
  


}