@import "../../../src/assets/styles/variables";

.projectPage {
  justify-content: center;
  align-content: center;
  margin: 0;
  padding-top: 65px;
  min-height: 100vh;

  .projectHeading {
    color: $orange;
    text-align: center;
    font-size: 28px;
    letter-spacing: 1px;
    text-shadow: 1px 1px 2px $navblue;
    padding-bottom: 20px;
  }
  .projectCardGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(275px, 370px));
    justify-content: center;
    align-content: center;
    margin: auto;
    max-width: 1250px;
  }
}

@media only screen and (min-width: 750px) {
  .projectPage {
    margin: 0 70px;
  }
}

@media only screen and (min-width: 768px) {
  .projectPage {
    padding-top: 20px;
    .projectHeading {
      font-size: 34px;
    }
  }
}

@media only screen and (min-width: 1024px) {

  .projectPage {
    .projectCardGrid {
        grid-template-columns: repeat(auto-fit, minmax(350px, 400px));
      }
  }
  


}
